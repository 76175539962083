.progressbar-container {
  height: 5px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 50px;
  margin: 2rem 0;
  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.progressbar-filler {
  height: 100%;
  border-radius: inherit;
  text-align: right;
}

.progressbar-label {
  padding: 5;
  color: white;
  font-weight: bold;
}
