.results-container {
  @media (max-width: 768px) {
    padding-bottom: 1rem;
  }
}
.score-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6rem;
  flex-wrap: wrap;
  margin-top: 3rem;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 0rem;
  }
}
.score-text-container {
  display: flex;
  align-items: center;
  margin-right: 5rem;
  @media (max-width: 768px) {
    justify-content: center;
    text-align: center;
    margin-bottom: 3rem;
    width: 100%;
    margin-right: 0rem;
  }
}
.score-bar-container {
  width: 30rem;
}
.email-container {
  border: 2px solid var(--black5);
  padding: 2.5rem 3rem;
  border-radius: 2rem;
  font-size: 1.5rem;
  width: 100%;
  background-color: var(--black6);
  margin-bottom: 3rem;
  .btn {
    width: fit-content;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}
.email-success-container {
  border: 2px solid var(--green);
  padding: 2.5rem 3rem;
  border-radius: 2rem;
  font-size: 1.5rem;
  width: 100%;
  /* background-color: var(--black6); */
  margin-bottom: 3rem;
}
.checkbox-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.label {
  display: inline;
  text-decoration: none;
  text-align: left;
  font-size: 1.5rem;
  color: var(--black2);
}
.email-text {
  margin-bottom: 1.5rem;
  font-weight: bold;
}
.email-success-text {
  font-weight: bold;
  color: var(--green);
}
.error-text {
  font-size: 1.3rem;
  color: var(--red);
  margin-top: -0.5rem;
}
.result-text {
  font-size: 1.8rem;
  line-height: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
}
