.option-container {
  display: flex;
  width: 100%;
  font-size: 1.5rem;
  border-radius: 0.8rem;
  background-color: var(--black6);
  user-select: none;
  margin-bottom: 1.2rem;
}
.option-container:last-child {
  margin-bottom: 0;
}

@media (hover: hover) {
  .option-container:hover {
    cursor: pointer;
    transform: translateY(-0.05rem);
    transition: transform 150ms;
    outline: var(--blue0) solid 0.2rem;
  }
}

.option-container:active {
  cursor: pointer;
  transform: translateY(-0.05rem);
  transition: transform 150ms;
  outline: var(--blue0) solid 0.2rem;
}

.option-prefix {
  padding: 1.5rem 2.5rem;
  background-color: #3b6ae3;
  color: white;
}

.option-text {
  padding: 1.5rem;
  width: 100%;
}

.question-title {
  padding-bottom: 1rem;
}

.question-container {
  transition: opacity 3s;
  opacity: 1;
}
.link {
  font-size: 1.5rem;
  margin-top: 1rem;
}
.back-button {
  font-size: 1.4rem;
  margin-top: 2rem;
  padding: 0.8rem 2rem;
  border: 0.2rem solid var(--black0);
  font-weight: 700;
  border-radius: 100rem;
  background-color: white;
  transition: background-color 0.1s ease;
}
.back-button:hover {
  cursor: pointer;
  background-color: var(--black6);
}
