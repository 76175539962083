.ScoreBar {
  position: relative;
}
.blurred {
  backdrop-filter: blur(20px);
  position: absolute;
  width: calc(100% + 6rem);
  left: -3rem;
  height: calc(100% + 10rem);
  top: -5rem;
  z-index: 2;
  @media (max-width: 768px) {
    top: -4rem;
  }
}
.stepValueWrapper {
  position: relative;
  font-size: 0.5rem;
  top: 0.75rem;
}
.stepValue {
  position: absolute;
  transform: translateX(-50%);
  color: #404040;
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.indicator {
  background: transparent;
  display: inline-block;
  height: 1rem;
  position: absolute;
  width: 1.6rem;
}
.indicator::before {
  border-bottom: 0.8rem solid #000000;
  border-left: 0.8rem solid transparent;
  border-right: 0.8rem solid transparent;
  content: '';
  height: 0;
  left: 0;
  position: absolute;
  top: -0.8rem;
  width: 0;
}
.indicator-title {
  padding-bottom: 12px;
}
