:root {
  --black: #000000;
  --black0: #182631; /* TechMiners black */
  --black1: #263540;
  --black2: #677986;
  --black3: #81919c;
  --black4: #a3afb9;
  --black5: #c5ced5;
  --black6: #f0f3f7;
  --blue0: #3b6ae3; /* TechMiners blue */
  --blue1: #7697ec;
  --blue2: #9db5f1;
  --blue3: #c4d2f7;
  --blue4: #d8e1f9;
  --blue5: #ebf0fc;
  --blue6: #f5f8fe;
  --white: #ffffff; /* TechMiners white */
  --grey: #263540;
  --lightGrey: #f0f3f7;
  --green: #a9dc77;
  --lightGreen: #dbec72;
  --red: #db4c5d;
  --lightRed: #ff8469;
  --lighterRed: #ffdbab;

  background-color: var(--black6);
  font-size: 62.5%;

  @media (max-width: 768px) {
    background-color: var(--white);
  }
}

* {
  box-sizing: border-box;
  font-family: 'Inter';
  margin: 0;
  padding: 0;
  color: var(--black0);
}

h1,
h2,
h3,
h4 {
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.5rem;
  color: var(--blue0);
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 700;
}

h3 {
  font-size: 2.2rem;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
}

/* UTILITIES */

.container {
  max-width: 100vw;
  min-height: 100dvh;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 6rem;
  align-items: flex-start;

  @media (max-width: 768px) {
    padding: 0rem;
  }
}

.container > * {
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.no-margin {
  margin: 0;
}

.btn {
  font-size: 1.8rem;
  font-weight: 700;
  padding: 1.2rem 2rem;
  min-height: 4.4rem;
  text-align: center;
  border: none;
  margin-bottom: 1rem;
  text-decoration: none;
  color: white;
  background-color: var(--blue0);
  border-radius: 100px;
  transition: background-color 0.1s ease;
}
.btn:hover {
  cursor: pointer;
  background-color: rgb(41, 74, 158);
}
.btn:disabled {
  background-color: var(--black4);
}

.btn[disabled]:hover {
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

.input {
  font-size: 1.5rem;
  padding: 1rem 1.2rem;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  background-color: white;
  display: inline;
}
.input:focus {
  outline: 0.1rem solid var(--blue0);
}

.checkbox {
  font-size: 1.5rem;
  padding: 0.75rem 1rem;
  border: 0.2rem solid var(--black5);
  border-radius: 1rem;
  margin-right: 1rem;
  text-decoration: none;
  background-color: white;
  display: inline;
  cursor: pointer;
}

.select {
  font-size: 1.8rem;
  padding: 1rem 0;
  width: 20rem;
  text-align: center;
  border: 0.1rem solid #3b6ae3;
  margin-bottom: 1rem;
  text-decoration: none;
  color: #3b6ae3;
  background-color: white;
  display: inline;
}
.label {
  display: inline;
  font-size: 1.8rem;
  padding: 1rem 0;
  text-align: center;
  padding-right: 1rem;
  text-decoration: none;
}

.logo {
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade {
  animation: fade 0.5s;
}
.divider {
  height: 12px;
  border: 0;
  box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.divider2 {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
  margin-bottom: 20px;
}

.content {
  background-color: white;
  border-radius: 2.4rem;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.03);

  @media (max-width: 768px) {
    padding: 2rem;
    padding-top: 2rem;
    border-radius: 0;
    box-shadow: none;
  }

  @media (min-width: 769px) {
    padding: 4rem 6rem 4rem 6rem;
  }
}
.button-intext {
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 1.8rem;
  line-height: 2.5rem;
}
