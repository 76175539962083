.teaser-container {
  margin: auto auto 3rem;
}
.teaser-title {
  margin-top: 2rem;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}
.teaser-text {
  font-size: 1.8rem;
  line-height: 2.5rem;
}
.teaser-strong {
  margin-top: 2.5rem;
  font-size: 1.8rem;
  font-weight: 600;
}
.teaser-cta-text {
  margin-top: 2.5rem;
  font-size: 1.8rem;
}
ul.teaser-bullets {
  margin-top: 1rem;
  margin-left: 4rem;
}
ul.teaser-bullets li {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
ul.teaser-bullets3 {
  display: inline-block;
  margin-top: 1rem;
  text-align: left;
}
ul.teaser-bullets3 li {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}
ul.teaser-tips {
  margin-top: 2rem;
  margin-left: 4rem;
}
ul.teaser-tips li {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.teaser-items {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.teaser-item-container {
  padding-bottom: 1.5rem;
}
.teaser-item-container:last-child {
  padding-bottom: 0;
}
.teaser-item-title {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.teaser-item-description {
  font-size: 1.5rem;
  text-align: left;
  line-height: 2rem;
}
.teaser-btn {
  margin: auto;
  line-height: 2.5rem;
}
.centered {
  text-align: center;
}
